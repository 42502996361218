import { Task } from '../../interfaces/objects';
import { TaskDO } from '../../interfaces/displayObjects';
import { TaskInfo, TaskInterface } from '../../interfaces/TaskInterface';
import { DexieCompletionCache, DexieTask } from './implementationModel/dexieObjects';
import { db } from './db';
import { v4 as uuid } from 'uuid';
import { liveQuery, Subscription } from 'dexie';
import { _changeLog, _list, _task } from '../ImplementationFactory';
import { ChangeLog, ChangeType } from '../../interfaces/changeLoggerInterface';
import { filterPropsToThoseOnType, logFriendlyObject } from '@otuvy/common-utils';
import { createCopyString } from '../../../../stringUtils';
import { COMPLETE, NOT_COMPLETE, MaxFieldLengths, CompletionType } from '../../../../../constants/constants';
import { calculateCurrentDueDate_old } from '../../../../../features/calendar/utils/recurrenceUtils';
import { RRule } from 'rrule';
import { SyncedOn } from '../../../../../sync/syncFromServer/downloadChanges';
import { AuthCache } from '@otuvy/auth';
import { convertToSyncedOns, createDebouncedCallback } from '../../syncUtil';
import { t } from 'i18next';
import { GenerateTasksResponse } from '../../../../../features/checklist/listApi';
import { EnvironmentConfig, getFlag } from '../../../../environmentUtils';
import { getDeviceCurrentPosition } from '../../../getDeviceCurrentPosition';

/**
 * TODO: move this inside of the actual TaskDexieImplementation class
 */
export const getTaskSyncedOns = async (
  excludedTaskIds: string[] = [],
  requireTaskIds: string[] = [],
  requireListIds: string[] = []
): Promise<SyncedOn[]> => {
  const tasks: DexieTask[] = await db().task.toArray();
  return convertToSyncedOns({
    recordsToConvert: tasks,
    excludedIds: excludedTaskIds,
    requiredIds: requireTaskIds,
    requiredParentIds: requireListIds,
    idExtractionMethod: (task: DexieTask) => {
      return task.taskId;
    },
    parentIdExtractionMethod: (task: DexieTask) => {
      return task.listId;
    },
  });
};

/**
 * TODO: move this inside of the actual TaskDexieImplementation class
 */
export const replaceUpdatedTasks = async (
  deletedTaskIds: string[],
  updatedWholeTasks: [DexieTask[], DexieCompletionCache[]]
) => {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
    console.log('Replacing updated tasks', deletedTaskIds, updatedWholeTasks);
  await db().transaction('rw', db().task, db().taskCompletionCache, async () => {
    const [updatedTasks, updatedCompletions] = updatedWholeTasks;
    const updatedTaskIds: string[] = updatedTasks.map((t) => t.taskId);

    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
      console.log('Deleting tasks and completions', deletedTaskIds, updatedTaskIds);
    await db()
      .task.where('taskId')
      .anyOf([...deletedTaskIds, ...updatedTaskIds])
      .delete();
    await db()
      .taskCompletionCache.where('taskId')
      .anyOf([...deletedTaskIds, ...updatedTaskIds])
      .delete();

    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
      console.log('Putting updated tasks and completions', updatedTasks, updatedCompletions);
    await db().task.bulkPut(updatedTasks);
    await db().taskCompletionCache.bulkPut(updatedCompletions);
  });
};

export class TaskDexieImplementation implements TaskInterface {
  private async getRawTaskById(taskId: string): Promise<DexieTask> {
    const matchingTasks = await db().task.where({ taskId }).toArray();

    if (!matchingTasks || matchingTasks.length === 0) {
      throw new Error('No tasks matching ID ' + taskId);
    }
    if (matchingTasks.length > 1) {
      console.error('Multiple tasks matching id ' + taskId, matchingTasks);
      throw new Error('Multiple tasks matching id ' + taskId);
    }

    return matchingTasks[0];
  }

  private async getCurrentCompletionForTask(
    dexieTask: DexieTask,
    debugIdentifier: string = 'unknown/CompletionForTask'
  ): Promise<DexieCompletionCache> {
    const cacheId = TaskDexieImplementation.calculateCurrentCacheIdForPreloadedTask(dexieTask);

    const result: DexieCompletionCache | undefined = await db()
      .taskCompletionCache.where({
        taskId: dexieTask.taskId,
        cacheId,
      })
      .first();
    if (result) {
      return result;
    }

    return {
      cacheId: cacheId,
      dueDate: calculateCurrentDueDate_old(dexieTask),
      taskId: dexieTask.taskId,
      listId: dexieTask.listId,
      isCompleted: NOT_COMPLETE, //no completions found, so we can assume that it is "incomplete"
    };
  }

  public static calculateCurrentCacheIdForPreloadedTask(dexieTask: DexieTask): string {
    return this.calculateCurrentCacheId(dexieTask);
  }

  private static calculateCurrentCacheId(task: Task): string {
    if (!task) {
      console.error('Attempting to calculate cacheID of empty task!');
      return '';
    }

    const currentDueDate = calculateCurrentDueDate_old(task);
    if (currentDueDate) {
      return '' + currentDueDate.valueOf(); //use the "ms since epoch" version rather than a string version to allow for consistency, namely consistent indexing, across devices and locals
    }

    return task.taskId;
  }

  private async deleteCompletionsForTaskById(taskId: string) {
    this.deleteCompletionsForMultipleTasksById([taskId]);
  }

  private async deleteCompletionsForMultipleTasksById(taskIds: string[]) {
    const completions = await db().taskCompletionCache.where('taskId').anyOf(taskIds).toArray();
    const photoIds: string[] = completions.flatMap((c) => c.completionPhotoIds ?? []);
    await db().photo.bulkDelete(photoIds);
    await db().thumbnail.bulkDelete(photoIds);
    await db().taskCompletionCache.where('taskId').anyOf(taskIds).delete();
  }

  private async castDexieToTask(dexieTask: DexieTask): Promise<TaskDO> {
    return await this.addCompletionToTask(
      dexieTask,
      await this.getCurrentCompletionForTask(dexieTask, 'cast dexie to task')
    );
  }

  private async castMultipleDexieToTask(dexieTasks: DexieTask[]): Promise<TaskDO[]> {
    if (!dexieTasks || dexieTasks.length === 0) return [];

    let taskIDs: string[] = dexieTasks.map((t) => t.taskId);

    let completionsMatchingAllTasks: DexieCompletionCache[] = [];
    if (!getFlag(EnvironmentConfig.SKIP_CALCULATING_COMPLETION)) {
      //When watching for slow spots in the app this lookup into taskCompletionCache is a slow spot
      completionsMatchingAllTasks = await db().taskCompletionCache.where('taskId').anyOf(taskIDs).toArray();
    }

    // Create a lookup map for completions indexed by taskId
    // This is to avoid having to search through all of the completions array for each task but instead just look up the completions for the current task
    const completionsMap = new Map<string, DexieCompletionCache[]>();
    completionsMatchingAllTasks.forEach((completion) => {
      const taskCompletions = completionsMap.get(completion.taskId) || [];
      taskCompletions.push(completion);
      completionsMap.set(completion.taskId, taskCompletions);
    });

    const tasks: TaskDO[] = await Promise.all(
      dexieTasks.map(async (dexieTask) => {
        // Get the completions for the current task
        // This is to avoid having to search through all of the completions array for each task but instead just look up the completions for the current task
        const taskCompletions = completionsMap.get(dexieTask.taskId) || [];

        const matchingCompletion: DexieCompletionCache | undefined = this.findMatchingCompletionForTask(
          dexieTask,
          taskCompletions
        );
        if (!matchingCompletion) {
          return {
            ...dexieTask,
            dueDate: calculateCurrentDueDate_old(dexieTask),
            isCompleted: false,
          };
        }

        return await this.addCompletionToTask(dexieTask, matchingCompletion);
      })
    );

    return tasks;
  }

  private findMatchingCompletionForTask = (
    dexieTask: DexieTask,
    possibleMatchingCompletions: DexieCompletionCache[]
  ): DexieCompletionCache | undefined => {
    return possibleMatchingCompletions.find((completion) => {
      if (getFlag(EnvironmentConfig.SKIP_CALCULATING_COMPLETION)) return false;

      const taskIDsMatch = completion.taskId === dexieTask.taskId;
      if (!taskIDsMatch) return false; //Shortcut calculations for completion when they are not needed

      const taskCacheID = TaskDexieImplementation.calculateCurrentCacheIdForPreloadedTask(dexieTask);
      const completionCacheIDsMatch = completion.cacheId === taskCacheID;
      return completionCacheIDsMatch;
    });
  };

  private async addCompletionToTask(dexieTask: Task, completion: DexieCompletionCache | null): Promise<TaskDO> {
    if (completion) {
      return {
        ...dexieTask,
        ...completion,
        isCompleted: completion.isCompleted === COMPLETE,
      };
    }

    const calculatedDueDate = calculateCurrentDueDate_old(dexieTask);

    return {
      ...dexieTask,
      dueDate: calculatedDueDate,
      isCompleted: false, //No existing completion info, so we can assume it is incomplete
    };
  }

  async getById(taskId: string): Promise<TaskDO> {
    const task: DexieTask = await this.getRawTaskById(taskId);
    return this.castDexieToTask(task);
  }

  private async updateTask(
    taskId: string,
    changes: Partial<TaskDO>, //needing to use a DO object rather than a core object shows that this function is too big (it updates more than tasks) and needs to be broken up to more specific updates
    changeType: ChangeType = ChangeType.TASK_EDIT
  ): Promise<TaskDO> {
    const oldTask: DexieTask = await this.getRawTaskById(taskId);
    const oldCompletion = await this.getCurrentCompletionForTaskById(taskId, 'Update Task');

    const taskChanges: Partial<DexieTask> = {
      ...filterPropsToThoseOnType(changes, new DexieTask()),
      updatedOn: new Date(),
    };

    await db().task.update(taskId, taskChanges);
    await db().list.update(oldTask.listId, { updatedOn: new Date() });

    const newTask: DexieTask = await this.getRawTaskById(taskId);

    const oldCacheID = TaskDexieImplementation.calculateCurrentCacheId(oldTask);
    const newCacheID = TaskDexieImplementation.calculateCurrentCacheId(newTask);

    await db().transaction('rw', db().taskCompletionCache, async () => {
      if (newCacheID !== oldCacheID) {
        await db()
          .taskCompletionCache.where({
            taskId,
            cacheId: oldCacheID,
          })
          .delete();
      }

      const updatedRecord = {
        ...oldCompletion,
        ...this.parseCompletionChanges(changes),
        cacheId: newCacheID,
      };

      await db().taskCompletionCache.put(updatedRecord);
    });

    await _changeLog.queueChange({
      changeType,
      recordId: taskId,
      payload: JSON.stringify(changes),
    });

    const result = await this.castDexieToTask(newTask);
    return result;
  }

  private parseCompletionChanges = (changes: Partial<TaskDO>): Partial<DexieCompletionCache> => {
    const { isCompleted, ...completionChangesWithoutIncompatibleProps } = filterPropsToThoseOnType(
      changes,
      new DexieCompletionCache()
    ) as Partial<DexieCompletionCache>;
    let completionChanges: Partial<DexieCompletionCache> = {
      ...completionChangesWithoutIncompatibleProps,
    };

    //If `isCompleted` is explicitly included in the changes, convert it to a CompletionType
    const isCompletedChange: CompletionType | undefined =
      changes.isCompleted === undefined ? undefined : changes.isCompleted ? COMPLETE : NOT_COMPLETE;
    //Don't include `isCompleted` in completionChanges unless it is explicitly included in the changes
    if (isCompletedChange !== undefined) {
      completionChanges = {
        ...completionChanges,
        isCompleted: isCompletedChange,
      };
    }

    return completionChanges;
  };

  private async bulkUpdateTasks(
    taskIds: string[],
    changes: Partial<TaskDO>,
    changeType: ChangeType = ChangeType.TASK_EDIT
  ): Promise<TaskDO[]> {
    const oldTasks: DexieTask[] = (await db().task.bulkGet(taskIds)).filter(
      (task): task is DexieTask => task !== undefined
    );
    const oldCompletions = await Promise.all(
      oldTasks.map((task) => this.getCurrentCompletionForTask(task, 'Update Task'))
    );

    const taskUpdates = oldTasks.map((oldTask) => ({
      key: oldTask.taskId,
      changes: {
        ...filterPropsToThoseOnType(changes, new DexieTask()),
        updatedOn: new Date(),
      },
    }));

    await db().task.bulkUpdate(taskUpdates);
    await db().list.bulkUpdate(oldTasks.map((task) => ({ key: task.listId, changes: { updatedOn: new Date() } })));

    const newTasks: DexieTask[] = (await db().task.bulkGet(taskIds)).filter(
      (task): task is DexieTask => task !== undefined
    );

    const oldCacheIDs = oldTasks.map((oldTask) => TaskDexieImplementation.calculateCurrentCacheId(oldTask));
    const newCacheIDs = newTasks.map((newTask) => TaskDexieImplementation.calculateCurrentCacheId(newTask));

    const updatedRecords = oldCompletions.map((oldCompletion, index) => {
      const updatedRecord = {
        ...oldCompletion,
        ...this.parseCompletionChanges(changes),
        cacheId: newCacheIDs[index],
      };

      return updatedRecord;
    });

    await db().transaction('rw', db().taskCompletionCache, async () => {
      for (let i = 0; i < taskIds.length; i++) {
        if (newCacheIDs[i] !== oldCacheIDs[i]) {
          await db()
            .taskCompletionCache.where({
              taskId: taskIds[i],
              cacheId: oldCacheIDs[i],
            })
            .delete();
        }
      }

      await db().taskCompletionCache.bulkPut(updatedRecords);
    });

    Promise.all(
      taskIds.map((taskId) =>
        _changeLog.queueChange({
          changeType,
          recordId: taskId,
          payload: JSON.stringify(changes),
        })
      )
    );

    return await this.castMultipleDexieToTask(newTasks);
  }

  /**
   * WARNING: This function is WIP. It is incomplete and untested.
   * Leaving this here as a reference for what the bulk update function should look like
   * We will use the method above instead of this one
   */
  // async bulkUpdateTasks(taskIds: string[], changes: Partial<TaskDO>): Promise<TaskDO[]> {
  //   const oldWholeTasks = await Promise.all(
  //     taskIds.map(async (taskId) => ({
  //       oldTask: await this.getRawTaskById(taskId),
  //       oldCompletion: await this.getCurrentCompletionForTaskById(taskId),
  //     }))
  //   );

  //   const newTasks: DexieTask[] = [];
  //   const newCompletions: DexieCompletionCache[] = [];

  //   for (const { oldTask, oldCompletion } of oldWholeTasks) {
  //     const newTask: DexieTask = {
  //       ...oldTask,
  //       ...filterPropsToThoseOnType(changes, new DexieTask()),
  //       updatedOn: new Date(),
  //     };
  //     newTasks.push(newTask);

  //     const oldCacheID = TaskDexieImplementation.calculateCurrentCacheId(oldTask);
  //     const newCacheID = TaskDexieImplementation.calculateCurrentCacheId(newTask);

  //     //FIXME: bulkify this
  //     if (newCacheID !== oldCacheID) {
  //       await db()
  //         .taskCompletionCache.where({
  //           taskId: oldTask.taskId,
  //           cacheId: oldCacheID,
  //         })
  //         .delete();
  //     }

  //     const completionChanges = this.parseCompletionChanges(changes);

  //     const newCompletion: DexieCompletionCache = {
  //       ...oldCompletion,
  //       ...completionChanges,
  //       cacheId: newCacheID,
  //     };
  //     newCompletions.push(newCompletion);
  //   }

  //   await db().transaction('rw', db().task, db().taskCompletionCache, async () => {
  //     await db().task.bulkPut(newTasks);
  //     await db().taskCompletionCache.bulkPut(newCompletions);
  //   });

  //   //FIXME: see what this needs to look like after upating the back end
  //   await _changeLog.queueChange({
  //     changeType: ChangeType.TASK_EDIT,
  //     recordId: '',
  //     payload: JSON.stringify({
  //       taskIds,
  //       changes,
  //     }),
  //   });

  //   const result = await Promise.all(newTasks.map(({ taskId }) => this.getById(taskId)));
  //   return result;
  // }

  async updateTaskName(taskId: string, newValue: string): Promise<TaskDO> {
    const changes: Partial<TaskDO> = { taskName: newValue };
    return this.updateTask(taskId, changes);
  }

  async updateTaskNotes(taskId: string, newValue: string): Promise<TaskDO> {
    const changes: Partial<TaskDO> = { notes: newValue };
    return this.updateTask(taskId, changes, ChangeType.COMPLETION_EDIT_NOTE);
  }

  async updateTaskInstructions(taskId: string, newValue: string): Promise<TaskDO> {
    const changes: Partial<TaskDO> = { instructions: newValue };
    return this.updateTask(taskId, changes);
  }

  async updateTaskLinkToInstructions(taskId: string, newUrlValue: string, newTextValue: string): Promise<TaskDO> {
    const changes: Partial<TaskDO> = {
      linkToInstructionsUrl: newUrlValue,
      linkToInstructionsText: newTextValue,
    };
    return this.updateTask(taskId, changes);
  }

  async updateTaskIsPhotoRequired(taskId: string, newValue: boolean): Promise<TaskDO> {
    const changes: Partial<TaskDO> = { isPhotoRequired: newValue };
    return this.updateTask(taskId, changes);
  }

  async updateMultipleTasksIsPhotoRequired(taskIds: string[], newValue: boolean): Promise<void> {
    const changes: Partial<TaskDO> = { isPhotoRequired: newValue };
    await this.bulkUpdateTasks(taskIds, changes);
  }

  async updateTaskRecurrence(
    taskId: string,
    newDueDate: Date | null,
    newRecurrence: RRule | null,
    timezone: string | undefined
  ): Promise<TaskDO> {
    const nonRecurringDueDate = newRecurrence ? null : newDueDate;
    const recurrence = newRecurrence ? newRecurrence.toString() : null;
    const dueDate = newRecurrence ? calculateCurrentDueDate_old({ recurrence, nonRecurringDueDate }) : newDueDate;

    const changes: Partial<TaskDO> = {
      //See the documentation on the object on how only one or the other of nonRecurringDueDate or recurrence should be set, not both
      nonRecurringDueDate,
      recurrence,
      // resetTime: newRecurrence ? await calculateCurrentResetTime({ recurrence, nonRecurringDueDate }) : null, //TODO: add this back in when resetTime is being finished
      dueDate,
      timezone,
    };

    return await this.updateTask(taskId, changes, ChangeType.TASK_EDIT_RECURRENCE);
  }

  /**
   * WARNING: This function is WIP. It is incomplete and untested.
   */
  async bulkUpdateTasksRecurrence(
    taskIds: string[],
    newDueDate: Date | null,
    newRecurrence: RRule | null,
    timezone: string | undefined
  ): Promise<TaskDO[]> {
    const nonRecurringDueDate = newRecurrence ? null : newDueDate;
    const recurrence = newRecurrence ? newRecurrence.toString() : null;
    const dueDate = newRecurrence ? calculateCurrentDueDate_old({ recurrence, nonRecurringDueDate }) : newDueDate;

    const changes: Partial<TaskDO> = {
      //See the documentation on the object on how only one or the other of nonRecurringDueDate or recurrence should be set, not both
      nonRecurringDueDate,
      recurrence,
      // resetTime: newRecurrence ? await calculateCurrentResetTime({ recurrence, nonRecurringDueDate }) : null, //TODO: add this back in when resetTime is being finished
      dueDate,
      timezone,
    };

    return await this.bulkUpdateTasks(taskIds, changes, ChangeType.TASK_EDIT_RECURRENCE);
  }

  async updateTaskCompletionStatus(taskId: string, newValue: boolean): Promise<TaskDO> {
    const currentUserID = AuthCache.getCurrentUserId();
    if (!currentUserID) throw new Error('No current user ID found');

    const completedBy: string | null = newValue ? currentUserID : null;
    const completedOn: Date | null = newValue ? new Date() : null;
    const updatedOn: Date = completedOn ?? new Date();

    const completionCoordinates = newValue ? await getDeviceCurrentPosition().catch(() => null) : null;

    const { latitude, longitude } = completionCoordinates?.coords ?? { latitude: null, longitude: null };

    const task = await this.getRawTaskById(taskId);
    const cacheId = TaskDexieImplementation.calculateCurrentCacheIdForPreloadedTask(task);

    await db().transaction('rw', db().taskCompletionCache, db().task, async () => {
      //Add completion info to the DO version of task
      const matchingCompletions = await db()
        .taskCompletionCache.where('cacheId')
        .equals(cacheId)
        .and((item) => item.taskId === taskId)
        .toArray();

      if (matchingCompletions && matchingCompletions.length === 1) {
        await db().taskCompletionCache.put({
          ...matchingCompletions[0],
          isCompleted: newValue ? COMPLETE : NOT_COMPLETE,
          completedBy: completedBy ? completedBy : undefined,
          completedOn: completedOn ? completedOn : undefined,
        });
      } else if (matchingCompletions.length > 1) {
        console.error('Multiple Completions found');
        return;
      } else {
        await db().taskCompletionCache.put({
          taskId: taskId,
          listId: task.listId,
          cacheId: cacheId,
          isCompleted: newValue ? COMPLETE : NOT_COMPLETE,
          completedBy: completedBy ? completedBy : undefined,
          completedOn: completedOn ? completedOn : undefined,
        });
      }

      const taskChanges: Partial<DexieTask> = {
        updatedOn,
      };
      await db().task.update(taskId, taskChanges);
      // for some reason an error occurs if I try list.update right here.
    });

    await db().list.update(task.listId, { updatedOn: new Date() });

    await _changeLog.queueChange({
      changeType: newValue ? ChangeType.COMPLETION_COMPLETE : ChangeType.COMPLETION_UNCOMPLETE,
      recordId: taskId,
      payload: newValue ? JSON.stringify({ latitude, longitude }) : '',
    });

    return await this.getById(taskId);
  }

  async updateTaskAssignment(taskId: string, newValue: string | null): Promise<TaskDO> {
    const currentUserID: string | undefined = AuthCache.getCurrentUserId();
    if (!currentUserID) throw new Error('No current user ID found');

    const changes: Partial<TaskDO> = {
      assignedTo: newValue,
      assignedBy: currentUserID,
    };
    return this.updateTask(taskId, changes);
  }

  /**
   * WARNING: This function is WIP. It is incomplete and untested.
   */
  async bulkUpdateTaskAssignments(taskIds: string[], newValue: string | null): Promise<TaskDO[]> {
    const currentUserID: string | undefined = AuthCache.getCurrentUserId();
    if (!currentUserID) throw new Error('No current user ID found');

    const changes: Partial<TaskDO> = {
      assignedTo: newValue,
      assignedBy: currentUserID,
    };
    return this.bulkUpdateTasks(taskIds, changes);
  }

  async deleteById(taskId: string): Promise<void> {
    await this.deleteMultipleTasksById([taskId], true);
  }

  async getTaskById(taskId: string): Promise<TaskDO> {
    return this.getById(taskId);
  }

  async getTasksByListId(listId: string): Promise<TaskDO[]> {
    const tasks: DexieTask[] = await db().task.where({ listId }).sortBy('sortId');

    return this.castMultipleDexieToTask(tasks);
  }

  async getLastTaskCreatedInList(listId: string): Promise<TaskDO | null> {
    const tasks: DexieTask[] = await db().task.where({ listId }).sortBy('createdOn');

    if (tasks.length === 0) return null;

    return this.castDexieToTask(tasks[tasks.length - 1]);
  }

  async getTasksByListIds(listIds?: string[]): Promise<TaskDO[]> {
    const tasks: DexieTask[] = listIds
      ? await db().task.where('listId').anyOf(listIds).toArray()
      : await db().task.toArray();

    return this.castMultipleDexieToTask(tasks);
  }

  private async getUnsortedTasksByListId(listId: string): Promise<DexieTask[]> {
    return await db().task.where('listId').equals(listId).toArray();
  }

  async countTasksInList(listId: string): Promise<number> {
    const tasks: DexieTask[] = await db().task.where('listId').equals(listId).toArray();

    return tasks.length;
  }

  async getTaskInfoForMultipleLists(listIds: string[]): Promise<TaskInfo[]> {
    const allListIds = (await _list.getAllVisibleListIds()).length === listIds.length;
    let tasks: TaskDO[] = [];
    if (allListIds) {
      tasks = await this.getTasksByListIds();
    } else {
      tasks = await this.getTasksByListIds(listIds);
    }

    const infoByListId = new Map<string, TaskInfo>();

    tasks.forEach((task) => {
      let infoForList: TaskInfo | undefined = infoByListId.get(task.listId);
      if (!infoForList)
        infoForList = {
          listId: task.listId,
          totalTasks: 0,
          completedTaskCount: 0,
          earliestIncompleteDueDate: null,
          mostRecentActivity: new Date(0),
        };

      infoForList.totalTasks++;
      if (task.isCompleted) {
        infoForList.completedTaskCount++;
      } else {
        //update due date
        const earliestIncompleteDueDate = infoForList.earliestIncompleteDueDate;
        if (
          !earliestIncompleteDueDate ||
          (task.dueDate && task.dueDate.getTime() < earliestIncompleteDueDate.getTime())
        ) {
          infoForList.earliestIncompleteDueDate = task.dueDate ?? null;
        }

        //update most recent activity
        const mostRecentActivity = infoForList.mostRecentActivity;
        if (task.updatedOn.getTime() > mostRecentActivity.getTime()) {
          infoForList.mostRecentActivity = task.updatedOn;
        }
      }

      infoByListId.set(task.listId, infoForList);
    });

    const results: TaskInfo[] = [];
    for (let i = 0; i < listIds.length; i++) {
      const listId = listIds[i];

      const taskInfoForList: TaskInfo | undefined = infoByListId.get(listId);
      if (taskInfoForList) {
        results.push(taskInfoForList);
      } else {
        results.push({
          listId,
          totalTasks: 0,
          completedTaskCount: 0,
          earliestIncompleteDueDate: null,
          mostRecentActivity: new Date(),
        });
      }
    }

    return results;
  }

  public async getCurrentCompletionForTaskById(
    taskId: string,
    debugIdentifier: string = 'unknown/CurrentCompletionForTaskById'
  ): Promise<DexieCompletionCache> {
    const task: DexieTask = await this.getRawTaskById(taskId);

    return await this.getCurrentCompletionForTask(task, debugIdentifier);
  }

  async createTask(name: string, listId: string, sortId?: number): Promise<TaskDO> {
    if (!name) throw new Error('No name provided for task');
    if (!listId) throw new Error('No list provided for task');

    const taskId = uuid();
    const newTask: DexieTask = {
      taskId,
      taskName: name,
      listId: listId,
      sortId: sortId ?? Date.now(),
      isPhotoRequired: false,
      createdOn: new Date(), //created now
      updatedOn: new Date(),
    };
    await db().task.add(newTask);
    await db().list.update(listId, { updatedOn: new Date() });
    await _changeLog.queueChange({
      changeType: ChangeType.TASK_CREATE,
      recordId: taskId,
      payload: JSON.stringify(newTask),
    });

    return this.getTaskById(taskId);
  }

  async bulkCreateTasks(taskData: Partial<Task>[], listId: string): Promise<TaskDO[]> {
    const taskObjects: DexieTask[] = [];
    const changeObjects: Partial<ChangeLog>[] = [];

    const taskDataWithNames: Partial<Task>[] = taskData.filter((task) => task.taskName);
    let sortId = Date.now();
    for (const task of taskDataWithNames) {
      const trimmedName = task.taskName!.trim();
      const taskId = task.taskId ?? uuid();

      const newTask: DexieTask = {
        taskId,
        taskName: trimmedName ?? t('default.task.name'),
        listId,
        sortId: sortId++,
        isPhotoRequired: task.isPhotoRequired ?? false,
        assignedTo: task.assignedTo,
        assignedBy: task.assignedBy,
        instructions: task.instructions,
        createdOn: new Date(), //created now
        updatedOn: new Date(),
      };

      taskObjects.push(newTask);

      changeObjects.push({
        changeType: ChangeType.TASK_CREATE,
        recordId: taskId,
        payload: JSON.stringify(newTask),
      });
    }

    await db().task.bulkAdd(taskObjects);
    await db().list.update(listId, { updatedOn: new Date() });

    await _changeLog.queueMultipleChanges(changeObjects);

    return await Promise.all(taskObjects.map((task) => this.castDexieToTask(task)));
  }

  async bulkCreateTasksFromStringWithNewLines({
    names: taskNames,
    listId,
  }: {
    names: string;
    listId: string;
  }): Promise<TaskDO[]> {
    if (!taskNames) throw new Error('No names provided for tasks');
    if (!listId) throw new Error('No list provided for task');

    function parseArrayOfStrings(namesString: string) {
      const split = namesString.split('\n');
      const truncated = split.map((str) => {
        const trimmed = str.trim();
        const truncated =
          trimmed.length > MaxFieldLengths.TASK_NAME ? trimmed.slice(0, MaxFieldLengths.TASK_NAME) : trimmed;
        return truncated.trim();
      });
      const filtered = truncated.filter((str) => str.length > 0); //filter out empty lines
      return filtered;
    }

    const names = parseArrayOfStrings(taskNames);
    const partialTasks = names.map<Partial<Task>>((n) => ({ taskName: n }));
    return this.bulkCreateTasks(partialTasks, listId);
  }

  async duplicateTask(taskId: string): Promise<TaskDO> {
    const oldTask = await this.getRawTaskById(taskId); //getting a full DO task here is way overkill, so we get a raw version
    if (!oldTask) throw new Error('Task to duplicate from not found');

    const newName: string = createCopyString(oldTask.taskName, MaxFieldLengths.TASK_NAME);

    return this.duplicateTaskAndAssignToList(taskId, newName, oldTask.listId);
  }

  async bulkDuplicateTasks(taskIds: string[]): Promise<TaskDO[]> {
    const oldTasks = await db().task.where('taskId').anyOf(taskIds).toArray();
    if (oldTasks.length === 0) throw new Error('No tasks to duplicate');

    const newNames = oldTasks.map((task) => createCopyString(task.taskName, MaxFieldLengths.TASK_NAME));

    const listIds = oldTasks.map((task) => task.listId);
    if (listIds.some((listId) => listId !== listIds[0])) {
      throw new Error('All tasks must be in the same list to duplicate');
    }

    await this.bulkDuplicateTaskAndAssignToList(oldTasks, oldTasks[0].listId, newNames);

    return await this.getTasksByListIds([oldTasks[0].listId]);
  }

  async completeTaskByID(taskId: string): Promise<void> {
    this.updateTaskCompletionStatus(taskId, true);
  }

  async uncompleteTaskByID(taskId: string): Promise<void> {
    this.updateTaskCompletionStatus(taskId, false);
  }

  async deleteMultipleTasksById(taskIds: string[], createChangeLogs: boolean): Promise<void> {
    await this.deleteCompletionsForMultipleTasksById(taskIds);
    await db().task.bulkDelete(taskIds);

    if (createChangeLogs) {
      for (let i = 0; i < taskIds.length; i++) {
        await _changeLog.queueChange({
          changeType: ChangeType.TASK_DELETE,
          recordId: taskIds[i],
        });
      }
    }
  }

  async deleteAllTasksForList(listId: string): Promise<void> {
    const tasks: DexieTask[] = await db().task.where({ listId }).toArray();
    await this.deleteMultipleTasks(tasks, false); //Rather than log individual task deletions we want to delete the tasks in a list on the server as part of the "deleteList" processor
  }

  async deleteMultipleTasks(tasks: Task[], createChangeLogs: boolean): Promise<void> {
    const taskIDs: string[] = tasks.map((task) => task.taskId);
    await this.deleteMultipleTasksById(taskIDs, createChangeLogs);
  }

  async duplicateMultipleTasksById(taskIds: string[]): Promise<TaskDO[]> {
    return await this.bulkDuplicateTasks(taskIds);
  }

  async duplicateMultipleTasks(tasks: Task[]): Promise<TaskDO[]> {
    const taskIDs: string[] = tasks.map((task) => task.taskId);
    return this.duplicateMultipleTasksById(taskIDs);
  }

  async completeMultipleTasksById(taskIds: string[]): Promise<void> {
    taskIds.forEach((taskId) => {
      this.completeTaskByID(taskId);
    });
  }

  async completeMultipleTasks(tasks: Task[]): Promise<void> {
    const taskIDs: string[] = tasks.map((task) => task.taskId);
    await this.completeMultipleTasksById(taskIDs);
  }

  async uncompleteMultipleTasksById(taskIds: string[]) {
    taskIds.forEach((taskId) => {
      this.uncompleteTaskByID(taskId);
    });
  }

  async uncompleteMultipleTasks(tasks: Task[]) {
    const taskIDs: string[] = tasks.map((task) => task.taskId);
    await this.uncompleteMultipleTasksById(taskIDs);
  }

  watchForChangesInTask(taskId: string, callback: Function, debugIdentifier?: string): any {
    if (!db().isOpen()) {
      console.warn('Database was closed when calling watchForChangesInTask');
      db().open();
    }
    const debouncedCallback = createDebouncedCallback(callback, 100);

    const taskObservable = liveQuery(() => db().task.where('taskId').equals(taskId).toArray());
    const completionObservable = liveQuery(() => db().taskCompletionCache.where('taskId').equals(taskId).toArray());
    const photoObservable = liveQuery(() => db().photo.toArray());

    let initialTaskLoad = true;
    let initialCompletionLoad = true;
    let initialPhotoLoad = true;

    const taskSubscription = taskObservable.subscribe({
      next: (result) => {
        if (!initialTaskLoad) debouncedCallback();
        initialTaskLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to task observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO:Throw/Handle this error?
    });

    const completionSubscription = completionObservable.subscribe({
      next: (result) => {
        if (!initialCompletionLoad) debouncedCallback();
        initialCompletionLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to completion observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO:Throw/Handle this error?
    });

    const photoSubscription = photoObservable.subscribe({
      start: (subscription: Subscription) => {
        //console.log('Task/Photo watch start', debugIdentifier);
      },
      next: (result) => {
        if (!initialPhotoLoad) debouncedCallback();
        initialPhotoLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to photo observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO:Throw/Handle this error?
    });

    return {
      taskSubscription,
      completionSubscription,
      photoSubscription,
    };
  }

  unwatch(subscriptions: any, debugIdentifier?: string) {
    if (!subscriptions) {
      console.warn('No subscriptions provided to task unwatch');
      return;
    }

    subscriptions.taskSubscription.unsubscribe();
    subscriptions.completionSubscription.unsubscribe();
    subscriptions.photoSubscription.unsubscribe();
  }

  async addCompletionPhotoToTask(taskId: string, newPhotoID: string): Promise<void> {
    //TODO: we can optimize the load by only calculating the cacheID once
    const matchingCompletion: DexieCompletionCache = await this.getCurrentCompletionForTaskById(taskId);

    let completionPhotoIds: string[] | undefined = matchingCompletion.completionPhotoIds;
    if (completionPhotoIds) {
      completionPhotoIds.push(newPhotoID);
    } else {
      completionPhotoIds = [newPhotoID];
    }

    await db().transaction('rw', db().taskCompletionCache, db().task, async () => {
      await db().taskCompletionCache.put({
        ...matchingCompletion,
        completionPhotoIds: completionPhotoIds,
      });

      const taskChanges: Partial<DexieTask> = {
        updatedOn: new Date(),
      };
      await db().task.update(taskId, taskChanges);
    });
    await db().list.update(matchingCompletion.listId, { updatedOn: new Date() });
  }

  async removeCompletionPhotoFromTask(taskId: string, oldPhotoID: string): Promise<void> {
    //TODO: we can optimize the load by only calculating the cacheID once
    const matchingCompletion: DexieCompletionCache = await this.getCurrentCompletionForTaskById(taskId);

    let completionPhotoIds: string[] | undefined = matchingCompletion.completionPhotoIds;
    if (completionPhotoIds) {
      const index = completionPhotoIds.indexOf(oldPhotoID);
      if (index > -1) {
        // only splice array when item is found
        completionPhotoIds.splice(index, 1);
      }
    } else {
      completionPhotoIds = [];
    }

    await db().transaction('rw', db().taskCompletionCache, db().task, async () => {
      await db().taskCompletionCache.put({
        ...matchingCompletion,
        completionPhotoIds: completionPhotoIds,
      });

      const taskChanges: Partial<DexieTask> = {
        updatedOn: new Date(),
      };
      await db().task.update(taskId, taskChanges);
    });
    await db().list.update(matchingCompletion.listId, { updatedOn: new Date() });
  }

  async duplicateTasksInList(oldListId: string, newListId: string): Promise<void> {
    const oldTasks = await this.getUnsortedTasksByListId(oldListId);

    await this.bulkDuplicateTaskAndAssignToList(oldTasks, newListId);
  }

  async bulkDuplicateTaskAndAssignToList(
    taskToDuplicate: DexieTask[],
    newListId: string,
    newName?: string[]
  ): Promise<void> {
    const newDate = new Date();
    const newTasks = taskToDuplicate.map((task, i) => {
      return {
        taskId: uuid(),
        taskName: newName ? newName[i] : task.taskName,
        listId: newListId,
        instructions: task.instructions,
        sortId: task.sortId + 10,
        isPhotoRequired: task.isPhotoRequired,
        nonRecurringDueDate: task.nonRecurringDueDate,
        recurrence: task.recurrence,
        // resetTime: taskToDuplicate.resetTime, //TODO: add this back in when resetTime is being finished

        createdOn: newDate,
        updatedOn: newDate,
      };
    });

    await db().task.bulkAdd(newTasks);
    await db().list.update(newListId, { updatedOn: new Date() });

    const changesObject = newTasks.map((task) => {
      return {
        changeType: ChangeType.TASK_CREATE,
        recordId: task.taskId,
        payload: JSON.stringify(task),
      };
    });

    await _changeLog.queueMultipleChanges(changesObject);
  }

  async duplicateTaskAndAssignToList(oldTaskId: string, newName: string, newListId: string): Promise<TaskDO> {
    const newId = uuid();

    const duplicateTask = await db().transaction(
      'rw',
      db().task,
      db().list,
      db().changeLog,
      db().taskCompletionCache,
      async () => {
        //Always load a fresh one from the DB or else some properties get lost along the way
        const taskToDuplicate = await this.getRawTaskById(oldTaskId);

        db().task.add({
          taskId: newId,
          taskName: newName,
          listId: newListId,
          instructions: taskToDuplicate.instructions,
          sortId: taskToDuplicate.sortId + 10,
          isPhotoRequired: taskToDuplicate.isPhotoRequired,
          nonRecurringDueDate: taskToDuplicate.nonRecurringDueDate,
          recurrence: taskToDuplicate.recurrence,
          // resetTime: taskToDuplicate.resetTime, //TODO: add this back in when resetTime is being finished

          createdOn: new Date(),
          updatedOn: new Date(),
        });

        const duplicateTask: TaskDO = await _task.getTaskById(newId);
        await db().list.update(newListId, { updatedOn: new Date() });

        return duplicateTask;
      }
    );

    await _changeLog.queueChange({
      changeType: ChangeType.TASK_CREATE,
      recordId: newId,
      //TODO: only those pieces related to Task and not a full DO version of the task
      payload: JSON.stringify(duplicateTask),
    });

    return duplicateTask;
  }

  async createTasksFromGeneratedTaskData(listId: string, taskData: GenerateTasksResponse[]): Promise<TaskDO[]> {
    const tasks: Partial<Task>[] = taskData.map(({ step }) => {
      // We were asked to remove duration for now, so it is not currently used
      return {
        taskName: step,
      };
    });
    return this.bulkCreateTasks(tasks, listId);
  }
}
