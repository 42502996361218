import { IonContent, IonModal } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { _user, _task, _list } from '../../../utils/state/model/implementations/ImplementationFactory';
import { UserDO } from '../../../utils/state/model/interfaces/displayObjects';
import DeleteConfirmation from '../../general/DeleteConfirmation';
import {
  getAllTasksByListIds,
  loadAssignInitialSelectedUsersData,
  loadPinnedUsers,
  loadShareInitialSelectedUsersData,
  savePinnedUsers,
  sortUsersByName,
} from '../utils/assignMenuUtils';
import AssignMenuHeader from './AssignMenuHeader';
import AssignMenuSearchInput from './AssignMenuSearchInput';
import AssignMenuUserList from './AssignMenuUserList';
import DeselectUsers from './DeselectUsers';
import { t } from 'i18next';
import { logFriendlyObject } from '@otuvy/common-utils';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { useHistory } from 'react-router-dom';

interface AssignMenuProps {
  isOpen: boolean;
  listId?: string | string[] | null;
  taskIds?: string[] | null;
  menuType: 'assign' | 'share' | null; //TODO: use an enum rather than a magic string
  onDismiss: () => void;
}

export interface UserDOWithRefs extends UserDO {
  dataModifiedTimeStamps: number | null;
}

const AssignMenu: React.FC<AssignMenuProps> = ({ isOpen, listId, taskIds, onDismiss: dismissMenu, menuType }) => {
  const [users, setUsers] = useState<UserDOWithRefs[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserDOWithRefs[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [pinnedUsers, setPinnedUsers] = useState<string[]>([]);
  const [isConfirmationBoxOn, setIsConfirmationBoxOn] = useState(false);
  const [isDoneButtonDisabled, setIsDoneButtonDisabled] = useState(true);
  const bufferSelectedUsers = useRef<string[]>([]);
  const [sharedWithUsers, setSharedWithUsers] = useState<string[]>([]);
  const [listOwners, setListOwners] = useState<string[]>([]);
  const { currentUserId } = useCurrentUser();
  const history = useHistory();

  const isTaskAlreadyAssigned = useRef<string[]>([]);
  const isEverySelectedUserAlreadyAssigned = (users = selectedUsers) => {
    if (isTaskAlreadyAssigned.current[0] === 'error') return false;
    return isTaskAlreadyAssigned.current.every((user) => users.includes(user));
  };

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!isOpen) return;
    _user
      .getAllUsers()
      .then((users) => {
        const sortedUsers = sortUsersByName([...users]);
        const usersWithNodeRef: UserDOWithRefs[] = sortedUsers.map((user) => ({
          ...user,
          dataModifiedTimeStamps: null,
        }));
        setUsers(usersWithNodeRef);
        setFilteredUsers(usersWithNodeRef);
      })
      .catch((err) => console.log('Error getting all users from DB', logFriendlyObject(err)));

    if (menuType === 'assign') {
      loadAssignInitialSelectedUsersData(listId, taskIds!, menuType)
        .then((users) => {
          !taskIds ? (isTaskAlreadyAssigned.current = [...(users as string[])]) : (isTaskAlreadyAssigned.current = []);
          setSelectedUsers(users.length > 1 && menuType === 'assign' ? [] : (users as string[]));
        })
        .catch((err) => console.log('Error loading initial selected users data', logFriendlyObject(err)));
    }

    if (menuType === 'share') {
      loadShareInitialSelectedUsersData(listId)
        .then((users) => {
          // Remove duplicates from owners and sharedWith
          const uniqueUsers = Array.from(new Set([...users.owners, ...users.sharedWith]));
          setSelectedUsers(uniqueUsers);
          setSharedWithUsers(users.sharedWith);
          setListOwners(users.owners);
        })
        .catch((err) => console.log('Error loading initial selected users data', logFriendlyObject(err)));
    }

    loadPinnedUsers().then((users) => setPinnedUsers(users));
  }, []);

  const onDoneClickHandler = async (users = selectedUsers) => {
    const promises = [];
    promises.push(savePinnedUsers(pinnedUsers));
    if (menuType === 'assign') {
      let taskToAssign = taskIds ? [...taskIds] : null;
      if (!taskToAssign) {
        taskToAssign = await getAllTasksByListIds(listId!);
      }
      const newAssignee = users.length > 0 ? users[0] : null;

      await _task.bulkUpdateTaskAssignments(taskToAssign ?? [], newAssignee);
    }
    if (menuType === 'share') {
      if (!listId) return;
      // TODO: Update owners
      if (typeof listId === 'string') {
        promises.push(_list.updateSharedWith(listId, users));
      } else {
        for (const listIds of listId) {
          promises.push(_list.updateSharedWith(listIds, users));
        }
      }
    }
    await Promise.all(promises);
    if (menuType === 'share' && currentUserId && !selectedUsers.includes(currentUserId)) {
      //TODO: If the current user is not in the selected users, navigate to the home page
      history.goBack();
    }
    dismissMenu();
  };

  const onCancelClickHandler = () => dismissMenu();

  const onUserAssignClickHandler = (userId: string) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers((prev) => prev.filter((id) => id !== userId));
    } else {
      if (isEverySelectedUserAlreadyAssigned([userId])) {
        return onDoneClickHandler([userId]);
      }
      setIsConfirmationBoxOn(true);
      bufferSelectedUsers.current = [userId];
      return;
    }
    //If we enable the done button first then there are issues when the user clicks done before the assignment completes
    setFilteredUsers(
      filteredUsers.map((user) => (user.userId === userId ? { ...user, dataModifiedTimeStamps: Date.now() } : user))
    );
    setIsDoneButtonDisabled(false);
  };

  const onUserShareClickHandler = (userId: string, type: 'owners' | 'sharedWith' | 'remove') => {
    // TODO: We can refactor this function to have less repetition, but I will leave it as is for now
    if (
      (!listOwners.includes(userId) && type === 'owners') ||
      (!sharedWithUsers.includes(userId) && type === 'sharedWith')
    ) {
      if (type === 'owners') {
        const updatedOwners = [...listOwners, userId];
        const updatedSelectedUsers = Array.from(new Set([...updatedOwners, ...sharedWithUsers]));
        setListOwners(updatedOwners);
        setSelectedUsers(updatedSelectedUsers);
      }

      if (type === 'sharedWith') {
        const updatedSharedWith = [...sharedWithUsers, userId];
        const updatedSelectedUsers = Array.from(new Set([...listOwners, ...updatedSharedWith]));
        setSharedWithUsers(updatedSharedWith);
        setSelectedUsers(updatedSelectedUsers);
      }
      setFilteredUsers(
        filteredUsers.map((user) => (user.userId === userId ? { ...user, dataModifiedTimeStamps: Date.now() } : user))
      );
      setIsDoneButtonDisabled(false);
      return;
    }

    if (type === 'owners' && listOwners.includes(userId)) {
      const updatedOwners = listOwners.filter((id) => id !== userId);
      const updatedSelectedUsers = Array.from(new Set([...updatedOwners, ...sharedWithUsers]));
      setListOwners(updatedOwners);
      setSelectedUsers(updatedSelectedUsers);
    }

    if (type === 'sharedWith' && sharedWithUsers.includes(userId)) {
      const updatedSharedWith = sharedWithUsers.filter((id) => id !== userId);
      const updatedSelectedUsers = Array.from(new Set([...listOwners, ...updatedSharedWith]));
      setSharedWithUsers(updatedSharedWith);
      setSelectedUsers(updatedSelectedUsers);
    }

    if (type === 'remove' && selectedUsers.includes(userId)) {
      const updatedOwners = listOwners.filter((id) => id !== userId);
      const updatedSharedWith = sharedWithUsers.filter((id) => id !== userId);
      const updatedSelectedUsers = Array.from(new Set([...updatedOwners, ...updatedSharedWith]));
      setListOwners(updatedOwners);
      setSharedWithUsers(updatedSharedWith);
      setSelectedUsers(updatedSelectedUsers);
    }
    // update filtered users state with new time stamp for the user
    setFilteredUsers(
      filteredUsers.map((user) => (user.userId === userId ? { ...user, dataModifiedTimeStamps: Date.now() } : user))
    );
    setIsDoneButtonDisabled(false);
  };

  const onInputChangedHandler = (searchValue: string) => {
    const filter = users.filter((user) => {
      const fullname = `${user.firstName} ${user.lastName}`;
      return fullname.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredUsers(filter);
  };

  const onPushPinClickHandler = (userId: string) => {
    setIsDoneButtonDisabled(false);
    if (pinnedUsers.includes(userId)) {
      setPinnedUsers((prev) => prev.filter((id) => id !== userId));
    } else {
      setPinnedUsers((prev) => [...prev, userId]);
    }
    setFilteredUsers(
      filteredUsers.map((user) => (user.userId === userId ? { ...user, dataModifiedTimeStamps: Date.now() } : user))
    );
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => dismissMenu()}
      onWillPresent={() => {
        const scrollEl = ionContentRef.current?.shadowRoot?.querySelector('.inner-scroll') as HTMLElement;
        setScrollElement(scrollEl);
      }}
      onDidPresent={() => {
        const scrollEl = ionContentRef.current?.shadowRoot?.querySelector('.inner-scroll') as HTMLElement;
        setScrollElement(scrollEl);
      }}
    >
      <AssignMenuHeader
        isDoneButtonDisabled={isDoneButtonDisabled}
        menuType={menuType}
        tasksCount={taskIds ? taskIds.length : 0}
        onCancelClickHandler={() => onCancelClickHandler()}
        onDoneClickHandler={() => onDoneClickHandler()}
      />
      <IonContent ref={ionContentRef} scrollY forceOverscroll>
        <AssignMenuSearchInput onInputChangedHandler={onInputChangedHandler} />
        {filteredUsers.length === users.length && (
          <DeselectUsers onClick={() => onDoneClickHandler([])} menuType={menuType} />
        )}
        <AssignMenuUserList
          filteredUsers={filteredUsers}
          pinnedUsers={pinnedUsers}
          selectedUsers={selectedUsers}
          sharedWithUsers={sharedWithUsers}
          listOwners={listOwners}
          onPushPinClickHandler={onPushPinClickHandler}
          onUserAssignClickHandler={onUserAssignClickHandler}
          onUserShareClickHandler={onUserShareClickHandler}
          menuType={menuType}
          ionContentRef={scrollElement}
        />
      </IonContent>
      <DeleteConfirmation
        isOpen={isConfirmationBoxOn}
        setIsOpen={setIsConfirmationBoxOn}
        deleteMe={() => onDoneClickHandler(bufferSelectedUsers.current)}
        confirmButtonText={t('assignMenu.deleteConfirmation.header')!}
        header={`${t('assignMenu.deleteConfirmation.header')}?`}
        message={t('assignMenu.deleteConfirmation.message')!}
      />
    </IonModal>
  );
};

export default AssignMenu;
