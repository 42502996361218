import { ListDO } from '../../interfaces/displayObjects';
import { DexieList } from './implementationModel/dexieObjects';
import { ListInterface } from '../../interfaces/ListInterface';
import { _changeLog, _list, _task, _user } from '../ImplementationFactory';
import { db } from './db';
import { v4 as uuid } from 'uuid';
import { liveQuery } from 'dexie';
import { MaxFieldLengths } from '../../../../../constants/constants';
import { logFriendlyObject } from '@otuvy/common-utils';
import { createCopyString } from '../../../../stringUtils';
import { ArrayPayload, ChangeType } from '../../interfaces/changeLoggerInterface';
import { SyncedOn } from '../../../../../sync/syncFromServer/downloadChanges';
import { AuthCache } from '@otuvy/auth';
import { convertToSyncedOns, createDebouncedCallback } from '../../syncUtil';
import { TaskInfo } from '../../interfaces/TaskInterface';
import { GenerateTasksResponse } from '../../../../../features/checklist/listApi';
import { EnvironmentConfig, getFlag } from '../../../../environmentUtils';

/**
 * TODO: move this to the actual interface/implementation
 */
export const getListSyncedOns = async (
  excludedListIds: string[] = [],
  requireListIds: string[] = []
): Promise<SyncedOn[]> => {
  const lists: DexieList[] = await db().list.toArray();
  return convertToSyncedOns({
    recordsToConvert: lists,
    excludedIds: excludedListIds,
    requiredIds: requireListIds,
    idExtractionMethod: (list: DexieList) => {
      return list.listId;
    },
  });
};

/**
 * TODO: move this to the actual interface/implementation
 */
export const replaceUpdatedLists = async (deletedListIds: string[], updatedLists: DexieList[]) => {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
    console.log('Replacing updated lists', deletedListIds, updatedLists);
  const updatedListIds: string[] = updatedLists.map((l) => l.listId);

  await db().transaction(
    'rw',
    [db().list, db().task, db().photo, db().taskCompletionCache, db().thumbnail, db().changeLog],
    async () => {
      if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('Deleting lists', deletedListIds, updatedListIds);
      await _list.deleteListsById({
        listIds: deletedListIds,
        createChangeLogs: false,
      });
      await _list.deleteListsById({
        listIds: updatedListIds,
        deleteDescendants: false,
        createChangeLogs: false,
      });

      if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('Putting updated lists', updatedLists);
      await db().list.bulkPut(updatedLists);
    }
  );
};

export class ListDexieImplementation implements ListInterface {
  private async getRawListById(listId: string): Promise<DexieList> {
    const matchingLists = await db().list.where({ listId }).toArray();

    if (!matchingLists || matchingLists.length === 0) {
      throw new Error('No lists matching list Id ' + listId);
    }
    if (matchingLists.length > 1) {
      throw new Error('Multiple lists matching id ' + listId);
    }
    return matchingLists[0];
  }

  private async castDexieToList(dexieList: DexieList): Promise<ListDO> {
    const { firstName, lastName } = await _user.getById(dexieList.owner);
    const ownerName = `${firstName} ${lastName}`;
    const taskInfo: TaskInfo[] = await _task.getTaskInfoForMultipleLists([dexieList.listId]);

    if (!taskInfo || taskInfo.length !== 1) {
      return {
        ...dexieList,
        isCompleted: false,
        numTasks: 0,
        numCompletedTasks: 0,
        dueDate: null,
        mostRecentActivity: new Date(),
        ownerName,
      };
    }

    return {
      ...dexieList,
      isCompleted: taskInfo[0].totalTasks > 0 && taskInfo[0].totalTasks === taskInfo[0].completedTaskCount,
      numTasks: taskInfo[0].totalTasks,
      numCompletedTasks: taskInfo[0].completedTaskCount,
      dueDate: taskInfo[0].earliestIncompleteDueDate,
      mostRecentActivity: taskInfo[0].mostRecentActivity,
      ownerName,
    };
  }

  private async castMultipleDexieToList(dexieLists: DexieList[]): Promise<ListDO[]> {
    const listIds: string[] = dexieLists.map((l) => l.listId);

    const users = await _user.getAllUsersAsMap();

    const infos: TaskInfo[] = await _task.getTaskInfoForMultipleLists(listIds);

    let results: ListDO[] = [];

    for (const list of dexieLists) {
      if (list) {
        const listId = list.listId;
        const owningUser = users.get(list.owner);

        const matchingInfos = infos.filter((i) => {
          return i.listId === listId;
        });

        if (matchingInfos && matchingInfos.length === 1) {
          const info = matchingInfos[0];
          results.push({
            ...list,
            isCompleted: info.totalTasks > 0 && info.totalTasks === info.completedTaskCount,
            numTasks: info.totalTasks,
            numCompletedTasks: info.completedTaskCount,
            dueDate: info.earliestIncompleteDueDate,
            mostRecentActivity: info.mostRecentActivity,
            ownerName: owningUser ? `${owningUser.firstName} ${owningUser.lastName}` : '', //If we're observing changes to users, this will be updated
          });
        }
      }
    }
    return results;
  }

  private async countTasksInList(listId: string): Promise<number> {
    const result = await _task.countTasksInList(listId);
    return result;
  }

  async getById(listId: string): Promise<ListDO> {
    const matchingList = await this.getRawListById(listId);

    return await this.castDexieToList(matchingList);
  }

  async deleteById(listId: string): Promise<void> {
    await this.deleteListsById({ listIds: [listId] });
  }

  async deleteListById(listId: string): Promise<void> {
    await this.deleteById(listId);
  }

  async deleteListsById({
    listIds,
    deleteDescendants = true,
    createChangeLogs = true,
  }: {
    listIds: string[];
    deleteDescendants?: boolean;
    createChangeLogs?: boolean;
  }): Promise<void> {
    console.log(
      `Deleting lists: deleteDescendants = ${deleteDescendants}, createChangeLogs = ${createChangeLogs}`,
      listIds.length > 1 ? listIds.length : listIds[0]
    );
    await db().list.where('listId').anyOf(listIds).delete();

    if (deleteDescendants) {
      for (const listId of listIds) {
        await _task.deleteAllTasksForList(listId);
      }
    }

    if (createChangeLogs) {
      for (const listId of listIds) {
        await _changeLog.queueChange({
          changeType: ChangeType.LIST_DELETE,
          recordId: listId,
        });
      }
    }
  }

  async getAllVisibleLists(): Promise<ListDO[]> {
    const matchingLists = await db().list.toArray();

    const result = await this.castMultipleDexieToList(matchingLists);

    return result;
  }

  async getAllVisibleListIds(): Promise<string[]> {
    const matchingListIds: string[] = (await db().list.toArray()).map((l) => l.listId);

    return matchingListIds;
  }

  async getAllVisibleListIdsWithSyncedOn(): Promise<SyncedOn[]> {
    const matchingListSyncedOns: SyncedOn[] = (await db().list.toArray()).map<SyncedOn>((l) => ({
      id: l.listId,
      syncedOn: l.syncedOn ?? new Date(0), //Beginning of epoch will be less than any other date in the system, so this will always be updated
    }));

    return matchingListSyncedOns;
  }

  async getListById(listId: string): Promise<ListDO> {
    return this.getById(listId);
  }

  async doesListExist(listId: string): Promise<boolean> {
    const matchingLists = await db().list.where({ listId }).toArray();

    return matchingLists.length > 0;
  }

  async createList(list: DexieList, triggerSync?: boolean): Promise<ListDO> {
    await db().list.add(list);

    await _changeLog.queueChange(
      {
        changeType: ChangeType.LIST_CREATE,
        recordId: list.listId,
        payload: JSON.stringify(list),
      },
      triggerSync
    );

    return this.getListById(list.listId);
  }

  async createListFromName(name: string): Promise<ListDO> {
    if (!name) throw new Error('No name provided when attempting to add a list');
    const currentUserID = AuthCache.getCurrentUserId();

    if (currentUserID === undefined) {
      console.error('Creating a list when we do not have a valid user to own it!');
      throw new Error('Creating a list when we do not have a valid user to own it!');
    }

    const list: DexieList = {
      listId: uuid(),
      listName: name,
      updatedOn: new Date(),
      sortId: Date.now(),
      createdOn: new Date(),
      owner: currentUserID,
      isArchived: false,
    };

    return this.createList(list);
  }

  async duplicateListById(listId: string): Promise<ListDO> {
    const oldList = await this.getRawListById(listId);
    if (!oldList) throw new Error('List to duplicate from not found');

    const currentUserID = AuthCache.getCurrentUserId();
    if (currentUserID === undefined) {
      console.error('Duplicating a list when we do not have a valid user to own it!');
      throw new Error('Duplicating a list when we do not have a valid user to own it!');
    }

    const newListId = uuid();
    const newListName: string = createCopyString(oldList.listName, MaxFieldLengths.LIST_NAME);

    const duplicateList: DexieList = {
      listId: newListId,
      listName: newListName,
      sortId: oldList.sortId + 10,
      createdOn: new Date(),
      updatedOn: new Date(),
      owner: currentUserID,
      isArchived: false,
    };

    await this.createList(duplicateList, false); //Do not trigger sync since we are going to insert more as part of the task duplication
    await _task.duplicateTasksInList(listId, newListId);
    return this.getListById(newListId);
  }

  async bulkDuplicateListsById(listIds: string[]): Promise<ListDO[]> {
    const oldLists = await db().list.where('listId').anyOf(listIds).toArray();

    if (oldLists.length !== listIds.length) {
      throw new Error('Error in bulkDuplicateListsById');
    }

    const currentUserID = AuthCache.getCurrentUserId();
    if (currentUserID === undefined) {
      console.error('Duplicating a list when we do not have a valid user to own it!');
      throw new Error('Duplicating a list when we do not have a valid user to own it!');
    }

    const newLists: DexieList[] = oldLists.map((oldList) => {
      const newListId = uuid();
      const newListName: string = createCopyString(oldList.listName, MaxFieldLengths.LIST_NAME);

      return {
        listId: newListId,
        listName: newListName,
        sortId: oldList.sortId + 10,
        createdOn: new Date(),
        updatedOn: new Date(),
        owner: currentUserID,
        isArchived: false,
      };
    });

    await db().list.bulkAdd(newLists);

    Promise.all(
      newLists.map((newList) => {
        return _changeLog.queueChange({
          changeType: ChangeType.LIST_CREATE,
          recordId: newList.listId,
          payload: JSON.stringify(newList),
        });
      })
    );

    Promise.all(
      newLists.map((newList, index) => {
        return _task.duplicateTasksInList(oldLists[index].listId, newList.listId);
      })
    );

    return await this.castMultipleDexieToList(newLists);
  }

  async updateList(
    listId: string,
    changes: Partial<ListDO>,
    changeType: ChangeType = ChangeType.LIST_EDIT
  ): Promise<ListDO> {
    const changesWithUpdatedOn: Partial<ListDO> = {
      ...changes,
      updatedOn: new Date(),
    };
    await db().list.update(listId, changesWithUpdatedOn);

    await _changeLog.queueChange({
      changeType,
      recordId: listId,
      payload: JSON.stringify(changes),
    });

    return this.getListById(listId);
  }

  async renameList(listId: string, newName: string): Promise<ListDO> {
    const changes: Partial<ListDO> = {
      listName: newName,
    };

    return this.updateList(listId, changes);
  }

  async updateSharedWith(listId: string, newSharedWith: string[]): Promise<ListDO> {
    const list: DexieList | undefined = await db().list.where({ listId }).first();
    if (!list) {
      throw new Error();
    }

    const oldSharedWith: string[] = list.sharedWith ?? [];
    const userIdsToAdd: string[] = newSharedWith.filter(
      (newUserId) => !oldSharedWith.includes(newUserId) && newUserId !== undefined && newUserId !== null
    );
    const userIdsToRemove: string[] = oldSharedWith.filter(
      (oldUserId) => !newSharedWith.includes(oldUserId) && oldUserId !== undefined && oldUserId !== null
    );
    if (userIdsToAdd.length === 0 && userIdsToRemove.length === 0) {
      return this.castDexieToList(list);
    }

    const changes: Partial<ListDO> = {
      sharedWith: newSharedWith,
    };
    await db().list.update(listId, changes);

    const payload: ArrayPayload = {
      toAdd: userIdsToAdd,
      toRemove: userIdsToRemove,
    };
    await _changeLog.queueChange({
      changeType: ChangeType.LIST_EDIT_SHARED_WITH,
      recordId: listId,
      payload: JSON.stringify(payload),
    });

    return this.getListById(listId);
  }

  watchForAnyChanges(callback: Function, debugIdentifier?: string): any {
    if (!db().isOpen()) {
      console.warn('Database was closed when calling watchForAnyChanges', debugIdentifier);
      db().open();
    }
    const debouncedCallback = createDebouncedCallback(callback, 100);

    const listObservable = liveQuery(() => db().list.toArray());
    const taskObservable = liveQuery(() => db().task.toArray());
    const completionObservable = liveQuery(() => db().taskCompletionCache.toArray());
    const userObservable = liveQuery(() => db().user.toArray());

    let initialListLoad = true;
    let initialTaskLoad = true;
    let initialCompletionLoad = true;
    let initialUserLoad = true;

    const listSubscription = listObservable.subscribe({
      next: (result) => {
        if (!initialListLoad) debouncedCallback();
        initialListLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to list observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO: handle this better?
    });

    const taskSubscription = taskObservable.subscribe({
      next: (result) => {
        if (!initialTaskLoad) debouncedCallback(debugIdentifier);
        initialTaskLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to task observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO:Throw/Handle this error?
    });

    const completionSubscription = completionObservable.subscribe({
      next: (result) => {
        if (!initialCompletionLoad) debouncedCallback(debugIdentifier);
        initialCompletionLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to completion observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO:Throw/Handle this error?
    });

    const userSubscription = userObservable.subscribe({
      next: (result) => {
        if (!initialUserLoad) debouncedCallback(debugIdentifier);
        initialUserLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to user observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO:Throw/Handle this error?
    });

    return {
      listSubscription,
      taskSubscription,
      completionSubscription,
      userSubscription,
    };
  }

  watchForChangesInList(listId: string, callback: Function, debugIdentifier?: string): any {
    const debouncedCallback = createDebouncedCallback(callback, 100);

    const listObservable = liveQuery(() => db().list.where({ listId }).toArray());

    const taskObservable = liveQuery(() => db().task.where({ listId }).toArray());

    const completionObservable = liveQuery(() => db().taskCompletionCache.where({ listId }).toArray());

    const userObservable = liveQuery(() => db().user.toArray());

    let initialListLoad = true;
    let initialTaskLoad = true;
    let initialCompletionLoad = true;
    let initialUserLoad = true;

    const listSubscription = listObservable.subscribe({
      next: (result) => {
        if (!initialListLoad) callback(debugIdentifier + ' - list');
        initialListLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to list observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO: handle this better?
    });

    const taskSubscription = taskObservable.subscribe({
      next: (result) => {
        if (!initialTaskLoad) debouncedCallback(debugIdentifier + ' - task');
        initialTaskLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to task observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO:Throw/Handle this error?
    });

    const completionSubscription = completionObservable.subscribe({
      next: (result) => {
        if (!initialCompletionLoad) debouncedCallback(debugIdentifier + ' - completion');
        initialCompletionLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to completion observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO:Throw/Handle this error?
    });

    const userSubscription = userObservable.subscribe({
      next: (result) => {
        if (!initialUserLoad) debouncedCallback(debugIdentifier + ' - user');
        initialUserLoad = false;
      },
      error: (error) =>
        console.error(`Error subscribing to user observable - ${debugIdentifier}`, logFriendlyObject(error)), //TODO: handle this better?
    });

    return {
      listSubscription,
      taskSubscription,
      completionSubscription,
      userSubscription,
    };
  }

  unwatch(subscriptions: any, debugIdentifier?: string): void {
    if (!subscriptions) {
      console.warn('No subscriptions provided to list unwatch');
      return;
    }

    //TODO: more data protection?
    subscriptions.listSubscription.unsubscribe();
    subscriptions.taskSubscription.unsubscribe();
    subscriptions.completionSubscription.unsubscribe();
    subscriptions.userSubscription.unsubscribe();
  }

  async createListAndTasksFromGeneratedTaskData(listName: string, taskData: GenerateTasksResponse[]): Promise<ListDO> {
    const { listId } = await this.createListFromName(listName);
    await _task.createTasksFromGeneratedTaskData(listId, taskData);
    return this.getById(listId);
  }
}
