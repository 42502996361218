import { IonActionSheet, IonButton, IonButtons, IonFooter, IonIcon, IonModal, IonToolbar } from '@ionic/react';
import {
  archiveOutline,
  calendarNumberOutline,
  copyOutline,
  ellipsisHorizontal,
  shareOutline,
  trashOutline,
} from 'ionicons/icons';
import { useRecoilState } from 'recoil';
import { multiSelectListItemsState } from './state/useMultiSelectLists';
import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { _list, _task } from '../../../utils/state/model/implementations/ImplementationFactory';
import { listMultiSelectModeAtom } from '../../../pages/ListsPage';
import CalendarView from '../../calendar/components/CalendarView';
import { TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import AssignMenu from '../../peopleorg/components/AssignMenu';
import { useAssignMenu } from '../../peopleorg/hooks/useAssignMenu';
import unarchiveLogo from '../../../assets/images/icons/Unarchive-outline.svg';
import addUserIcon from '../../../assets/images/icons/Add_User_Icon.svg';

interface ListMultiSelectActionSheetProps {}

const ListMultiSelectActionSheet: React.FC<ListMultiSelectActionSheetProps> = () => {
  const [isMultiSelectMode, setIsMultiSelectMode] = useRecoilState(listMultiSelectModeAtom);
  const [multiSelectedItems, setMultiSelectedItems] = useRecoilState(multiSelectListItemsState);
  const { isOpen, list: menuList, mode: assignMenuMode, openMenu, closeMenu, key: assignMenuKey } = useAssignMenu();

  const [listsTasks, setListsTasks] = useState<TaskDO[]>([]);
  const [isEllipsisMenuOpen, setIsEllipsisMenuOpen] = useState<boolean>(false);

  const calendarModalRef = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    const getTasksForLists = async () => {
      const promise = multiSelectedItems.map((list) => {
        return _task.getTasksByListId(list.listId);
      });
      const tasks = await Promise.all(promise);

      const flatTasks = tasks.flat();
      setListsTasks(flatTasks);
    };
    getTasksForLists();
  }, [multiSelectedItems]);

  const onCalendarCancelClickHandler = async () => {
    await calendarModalRef.current?.dismiss();
    setIsMultiSelectMode(false);
    setMultiSelectedItems([]);
    setIsEllipsisMenuOpen(false);
  };

  const duplicateMe = async () => {
    await _list.bulkDuplicateListsById(multiSelectedItems.map((list) => list.listId));

    setIsMultiSelectMode(false);
    setMultiSelectedItems([]);
    setIsEllipsisMenuOpen(false);
  };

  const deleteMe = async () => {
    const promises = multiSelectedItems.map((list) => {
      return _list.deleteListById(list.listId);
    });
    await Promise.all(promises);
    setIsMultiSelectMode(false);
    setMultiSelectedItems([]);
    setIsEllipsisMenuOpen(false);
  };

  const archiveMe = async () => {
    const promises = multiSelectedItems.map((list) => {
      return _list.updateList(list.listId, { isArchived: areAllListsArchived ? false : true });
    });
    await Promise.all(promises);
    setIsMultiSelectMode(false);
    setMultiSelectedItems([]);
    setIsEllipsisMenuOpen(false);
  };

  const assignMe = async () => {
    openMenu(
      multiSelectedItems.map((list) => list.listId),
      null,
      'assign'
    );
  };

  const shareMe = async () => {
    openMenu(
      multiSelectedItems.map((list) => list.listId),
      null,
      'share'
    );
  };

  if (multiSelectedItems.length === 0) return null;

  const areAllListsArchived = multiSelectedItems.every((list) => list.isArchived);

  return (
    <>
      <IonFooter className="action-buttons">
        <IonToolbar>
          <IonButtons class="ion-justify-content-center ion-align-items-center ion-padding-vertical">
            <IonButton
              // disabled={!isListOwner}
              className="circle-button ion-text-center custom-button-radius"
              fill="solid"
              color="primary"
              id="open-calendar-modal-multi-list-select"
            >
              <IonIcon slot="icon-only" size="large" icon={calendarNumberOutline} />
            </IonButton>
            <IonButton
              className="circle-button ion-text-center custom-button-radius"
              fill="solid"
              color="primary"
              onClick={() => {
                assignMe();
              }}
            >
              <IonIcon slot="icon-only" size="medium" icon={addUserIcon} />
            </IonButton>
            <IonButton
              className="circle-button ion-text-center custom-button-radius"
              fill="solid"
              color="primary"
              // disabled={!isListOwner || selectedItems.some((task) => task.isCompleted)}
              onClick={() => setIsEllipsisMenuOpen(true)}
            >
              <IonIcon slot="icon-only" size="large" icon={ellipsisHorizontal} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <IonActionSheet
        isOpen={isEllipsisMenuOpen}
        buttons={[
          {
            text: `${t('assign')} ${t('list', { count: multiSelectedItems.length })}`,
            icon: addUserIcon,
            handler: assignMe,
          },
          {
            text: `${t('share')} ${t('list', { count: multiSelectedItems.length })}`,
            icon: shareOutline,
            handler: shareMe,
          },
          {
            text: `${t('duplicate')} ${t('list', { count: multiSelectedItems.length })}`,
            icon: copyOutline,
            handler: duplicateMe,
          },
          {
            text: `${areAllListsArchived ? t('unarchive') : t('archive')} ${t('list', {
              count: multiSelectedItems.length,
            })}`,
            icon: areAllListsArchived ? unarchiveLogo : archiveOutline,
            handler: archiveMe,
          },
          {
            text: `${t('delete')} ${t('list', { count: multiSelectedItems.length })}`,
            role: 'destructive',
            icon: trashOutline,
            handler: deleteMe,
          },
          {
            text: t('cancel')!,
            role: 'cancel',
          },
        ]}
        onWillDismiss={() => setIsEllipsisMenuOpen(false)}
      />
      <IonModal ref={calendarModalRef} trigger="open-calendar-modal-multi-list-select">
        <CalendarView onCancel={onCalendarCancelClickHandler} tasks={listsTasks} />
      </IonModal>
      <AssignMenu
        key={assignMenuKey}
        isOpen={isOpen}
        listId={menuList}
        onDismiss={closeMenu}
        menuType={assignMenuMode}
      />
    </>
  );
};

export default ListMultiSelectActionSheet;
